<template lang="pug">
    div.container.no-banner(v-html="customContent.cookie_policy")
</template>
<script>
//import store from "../store";
//import { mapMutations } from 'vuex';
import store from '../store'
import { mapState } from 'vuex'


const globalPreloader = document.getElementById('global-preloader');

globalPreloader.style.display = 'none';

/*const setNewResellerData = async () => {
  await store.dispatch('reseller/getResellerData');

  await store.dispatch('reseller/updateBuilder');
}*/

//let resellerInfo = JSON.parse(localStorage.getItem('resellerInfo'));

//set data for the component
export default {
  name: 'CookiePolicy',
  data() {
    return {
     // customContent: null,
    }
  },
  created() {
    //console.log('mounted');
    //run asyncData on mounted
    this.asyncData();

  },
  computed: {
    ...mapState('reseller', ['customContent']),
  },
  methods: {
   async asyncData() {
    //  console.log('asyncData');
     await store.dispatch('reseller/getCustomContentData');

     // this.customContent = store.getters['reseller/getCustomContentData'];

     console.log(this.customContent);
  //   console.log(customContent);
    },
  }
}



//!resellerInfo ? setNewResellerData() : store.commit('reseller/setResellerInfo', resellerInfo);
</script>
<style lang="sass">

  .no-banner
    padding-top: 5rem
    padding-bottom: 5rem
.customizable
  .home-page
      .bottom
        display: none
</style>